@use 'globals' as *;

@font-face {
    font-family: 'icomoon';
    src: url('../icons/app/icomoon.eot?#{$timestamp}');
    src: url('../icons/app/icomoon.eot?#{$timestamp}#iefix') format('embedded-opentype'),
         url('../icons/app/icomoon.ttf?#{$timestamp}') format('truetype'),
         url('../icons/app/icomoon.woff?#{$timestamp}') format('woff'),
         url('../icons/app/icomoon.svg?#{$timestamp}#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon-logo-icon-x:before {
    content: '\e901';
}

.icon-logo-icon-linkedin:before {
    content: '\e902';
}

.icon-logo-icon-xing:before {
    content: '\e903';
}

.icon-share:before {
    content: '\e900';
}
