@use 'globals' as *;

* {
    color: $color-white;
    font-size: $font-size-14px;
    scrollbar-color: rgba(144, 187, 198, 0.3) $transparent;
    scrollbar-width: thin;
    @include font-family('Roboto');

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(144, 187, 198, 0.3);
        border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
        background-color: $transparent;
    }
}

body {
    width: 100%;
    height: 100%;
    overflow: hidden;

    &.footer-is-visible {
        overflow-y: auto;
    }
}

#main {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    > section {
        display: flex;
        position: absolute;
        top: 100vh;
        bottom: 61px;
        left: 0;
        right: 71px;
        z-index: 3;
        overflow: hidden;
        background: linear-gradient(to top, $transparent, rgba(17, 44, 51, 0.75));
        @include backdrop-filter(blur(10px));
        @include flex-wrap(wrap);
        @include gap(0 40px);
        @include opacity(0);
        @include transition(all, 0.5s, $ease-in-out-cubic, 0s);

        > .stage {
            display: flex;
            position: relative;
            z-index: 1;
            width: 100%;
            margin-right: 70px;
            padding: 0 5px 0 30px;
            max-height: calc(100% - 72px);
            @include gap(60px);

            h2 {
                margin-bottom: 20px;
                color: $color-blue-sunny;
                font-size: $font-size-30px;
                line-height: 1em;
                @include font-family('Inversionz');
            }

            > .content {
                position: relative;
                max-width: 800px;
                max-height: 100%;
                padding-right: 20px;
                overflow-x: hidden;
                overflow-y: scroll;
                @include opacity(0);
                @include flex(1 1 calc(50% - 20px));
                @include transition(all, 0.5s, ease-out, 0s);

                > p {
                    margin-bottom: 10px;
                    hyphens: auto;
                }

                > div {
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 20px;

                    @include gap(20px);

                    > article {
                        display: flex;
                        flex-direction: column;
                        padding: 8px 10px;
                        background: rgba(0, 0, 0, 0.3);
                        @include gap(3px 0);
                        @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

                        > h3,
                        > p {
                            display: flex;
                            @include gap(0 3px);

                            > span {
                                padding: 4px 10px 2px 10px;
                                background: rgba(0, 0, 0, 0.2);
                                @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

                                &.key {
                                    font-weight: 400;
                                    background: rgba(0, 0, 0, 0.4);
                                    @include flex(0 0 120px);
                                }

                                &.value {
                                    display: flex;
                                    hyphens: auto;
                                    @include flex-wrap(wrap);
                                    @include flex(1 0);
                                    @include gap(3px);

                                    &.value:has(.mixin-skill-link) {
                                        max-width: 100%;
                                        padding: 5px 8px;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }

                                    > a {
                                        font-weight: 400;

                                        &:not(.mixin-skill-link) {
                                            color: $color-blue-sunny;
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }

                        > h3 {

                            > span {
                                background: rgba(0, 0, 0, 0.4);

                                &.value {
                                    font-weight: 400;
                                    color: $color-blue-sunny;

                                    &.color-white {
                                        color: $color-white;
                                    }
                                }
                            }
                        }

                        > ul {
                            display: flex;
                            flex-direction: row;
                            @include gap(3px);

                            > li {
                                @include flex(0 0);
                            }
                        }

                        > .number {
                            position: relative;
                            display: block;
                            font-size: $font-size-24px;
                            line-height: 1em;
                            text-align: right;
                            @include font-family('Inversionz');

                            &:before {
                                content: '_';
                                color: $color-blue-sunny;
                                font-size: $font-size-20px;
                                font-weight: 400;
                                letter-spacing: 2px;
                                @include font-family('Roboto');
                            }

                            &:after {
                                content: '---';
                                position: absolute;
                                top: -2px;
                                left: 5px;
                                color: $color-blue-sunny;
                                font-size: $font-size-20px;
                                font-weight: 400;
                                letter-spacing: 2px;
                                @include font-family('Roboto');
                            }
                        }
                    }
                }

                .btn {
                    display: flex;
                    height: 26px;
                    padding: 1px 10px 0 10px;
                    background: rgba(0, 0, 0, 0.4);
                    cursor: pointer;
                    border: 1px solid $transparent;
                    @include gap(0 5px);
                    @include transition(border-color, 0.1s, ease-out, 0s);
                    @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

                    .text {
                        color: $color-white;
                        font-weight: 400;
                        white-space: nowrap;
                    }

                    .font-icon {
                        color: $color-blue-sunny;
                        font-size: $font-size-24px;
                        line-height: 1em;
                        @include font-family('Inversionz');
                    }

                    &:hover:not(.no-css-hover) {
                        border-color: $color-white;
                    }
                }
            }

            > .matrix {
                @include flex(1 1 calc(50% - 20px));
            }
        }

        &.show-section {
            top: 0;
            overflow: visible;
            @include opacity(1);

            &.show-content {

                > .stage {

                    > .content {
                        @include opacity(1);
                    }
                }
            }

            #module-share-links {
                @include opacity(1);
            }
        }
    }

    .stage-line-horizontal {
        position: absolute;
        bottom: 60px;
        right: 0;
        z-index: 10;
        width: 0;
        height: 1px;
        background: $color-white;
        pointer-events: none;
        @include transition(width, 0.5s, $ease-in-out-cubic, 0s);
    }

    .stage-line-vertical {
        position: absolute;
        top: 0;
        right: 70px;
        z-index: 10;
        width: 1px;
        height: 0;
        background: linear-gradient(to top, $color-white 0%, $color-blue-aqua 25%, $color-blue-sky 50%);
        pointer-events: none;
        @include transition(height, 0.5s, $ease-in-out-cubic, 0s);
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 600vw;
        height: 100%;
        pointer-events: none;
        @include opacity(0.2);
        @include bg-img('../images/assets/app/layout/cloud-01.png?#{$timestamp}', 300vw 100vh, bottom center, repeat-x);
        @include animation(cloud-1, 180s, linear, infinite);
    }

    &:after {
        content: '';
        position: absolute;
        top: 50px;
        left: 0;
        z-index: 1;
        width: 600vw;
        height: 50%;
        pointer-events: none;
        @include opacity(0.3);
        @include bg-img('../images/assets/app/layout/cloud-02.png?#{$timestamp}', 300vw 50vh, bottom center, repeat-x);
        @include animation(cloud-2, 240s, linear, infinite);
    }
}

#header {
    position: relative;
    width: 100%;
    height: 100%;

    h1 {
        position: absolute;
        width: 100%;
        height: 100%;
        @include bg-img('../images/assets/app/layout/background.jpg?#{$timestamp}');

        span {
            display: none;
        }
    }

    &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background-image: linear-gradient(rgba(0, 68, 84, 0.05) 1px, transparent 1px), linear-gradient(90deg, rgba(0, 68, 84, 0.05) 1px, transparent 1px);
        background-size: 3px 3px;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 60%;
        pointer-events: none;
        @include gradient-streight(top, rgba(0, 68, 84, 0.5), 0, rgba(0, 0, 0, 0), 100%);
    }
}

#footer {
    position: relative;
    z-index: 2;

    #slideup {
        @include gradient-streight(top, $color-white, 0, #004454, 1px);

        .links {
            display: flex;
            gap: 0 20px;
            position: absolute;
            top: -60px;
            padding: 0 30px;

            .label {
                margin: 0;
                font-size: $font-size-16px;
                line-height: 60px;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .close {
            top: 20px;
            right: 30px;
            color: $color-white;
            font-size: $font-size-16px;
            text-decoration: underline;
        }

        .content {
            padding: 60px 30px 20px 30px;
            border-top: 1px solid $color-white;

            h4 {
                margin-left: -10px;
                padding-bottom: 20px;
                font-size: $font-size-40px;
                line-height: 1em;
                @include font-family('Inversionz');
            }

            h5 {
                padding: 20px 0 10px 0;
                font-weight: 400;
                text-transform: uppercase;
            }

            p {
                padding-bottoM: 10px;
                color: $color-white;
            }

            a {
                text-decoration: underline;
            }

            ul {
                padding-top: 20px;

                li {
                    position: relative;
                    padding-left: 20px;
                    color: $color-white;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 9px;
                        left: 0;
                        width: 6px;
                        height: 6px;
                        background: $color-white;
                        @include border-radius(50%, 50%, 50%, 50%);
                    }
                }
            }
        }
    }
}

#module-nav-default {
    position: absolute;
    top: 18px;
    left: 20px;
    z-index: 10;

    a {
        width: 40px;
        height: 40px;
        padding: 10px;
        cursor: pointer;
        @include user-select(none);

        span {
            display: block;
            position: relative;
            margin-bottom: 3px;
            width: 100%;
            height: 2px;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: $color-white;
                @include transition(all, 0.3s, $ease-in-out-cubic, 0s);
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:nth-child(2) {

                &:after {
                    width: 10px;
                }
            }

            &:nth-child(3) {

                &:after {
                    width: 15px;
                }
            }

            &:nth-child(4) {

                &:after {
                    width: 5px;
                }
            }
        }

        &:hover:not(.no-css-hover) {

            span {

                &:after {
                    width: 100%;
                }
            }
        }
    }
}

#module-nav-insights {
    display: flex;
    gap: 0 25px;
    justify-content: right;
    position: relative;
    z-index: 2;
    width: 100%;
    height: 70px;
    padding: 25px 40px;

    a {
        font-weight: 400;

        &:before,
        &:after {
            position: relative;
            pointer-events: none;
            @include opacity(0);
            @include transition(all, 0.2s, ease-out, 0s);
        }

        &:before {
            content: '[';
            left: -15px;
        }

        &:after {
            content: ']';
            right: -15px;
        }

        &.active,
        &:hover:not(.no-css-hover) {

            &:before,
            &:after {
                @include opacity(1);
            }

            &:before {
                content: '[';
                left: -3px;
            }

            &:after {
                content: ']';
                right: -3px;
            }
        }
    }
}

#module-nav-services {
    display: flex;
    gap: 0 15px;
    justify-content: right;
    position: absolute;
    top: 30px;
    right: 20px;
    z-index: 4;
    writing-mode: vertical-rl;
    @include flex-wrap(wrap);
    @include rotate-z(0, 180deg, center, center);

    a {
        position: relative;
        display: inline-block;
        color: $color-white;
        font-size: $font-size-18px;
        @include user-select(none);

        &:before,
        &:after {
            position: relative;
            pointer-events: none;
            @include opacity(0);
            @include transition(all, 0.2s, ease-out, 0s);
        }

        &:before {
            content: '[';
            bottom: 15px;
        }

        &:after {
            content: ']';
            top: 15px;
        }

        &.active,
        &:hover:not(.no-css-hover) {

            &:before,
            &:after {
                @include opacity(1);
            }

            &:before {
                content: '[';
                bottom: 3px;
            }

            &:after {
                content: ']';
                top: 3px;
            }
        }
    }
}

#module-three-cylinder {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    overflow: hidden;
    @include opacity(0);
    @include transition(all, 6s, ease-in, 0s);

    canvas {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
    }

    .skill {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 10;
        padding: 10px;
        cursor: pointer;
        pointer-events: none !important;
        @include transition(background, 0.3s, ease-out, 0s);

        .id {
            color: rgba(127, 255, 255, 0.75);
            font-size: $font-size-18px;
            font-weight: 400;
            line-height: 1em;
            text-align: right;
            @include flex(1 0);
            @include transition(all, 0.3s, ease-out, 0s);
        }

        .name {
            max-height: 60px;
            color: rgba(255, 255, 255, 0.75);
            font-size: $font-size-40px;
            font-weight: 600;
            line-height: 0.7em;
            text-align: center;
            text-shadow: 0 0 10px rgba(0, 255, 255, 0.95);
            word-break: break-word;
            letter-spacing: -5px;
            overflow: hidden;
            @include flex(0 0 auto);
            @include font-family('Inversionz');
            @include transition(all, 0.3s, ease-out, 0s);

            span {
                display: inline-block;
                margin: 0 -5px;
                color: inherit;
                font: inherit;
                line-height: inherit;
                letter-spacing: inherit;
            }
        }

        .label {
            display: flex;
            flex-direction: column;
            justify-content: end;
            margin-top: 5px;
            color: rgba(127, 255, 255, 0.75);
            font-size: $font-size-18px;
            font-weight: 400;
            line-height: 1em;
            text-align: center;
            text-transform: uppercase;
            @include flex(1 0);
            @include transition(all, 0.3s, ease-out, 0s);
        }

        &:before {
            content: '[';
            position: absolute;
            top: 50px;
            right: calc(100% + 30px);
            transform: scale(3, 11);
            @include opacity(0);
            @include transition(all, 0.3s, ease-out, 0s);
        }

        &:after {
            content: ']';
            position: absolute;
            top: 50px;
            left: calc(100% + 30px);
            transform: scale(3, 11);
            @include opacity(0);
            @include transition(all, 0.3s, ease-out, 0s);
        }
    }

    &.show {
        @include opacity(1);
    }

    &.loaded {
        @include transition(all, 0.5s, ease-in, 0s);

        .skill {
            pointer-events: auto !important;

            // &.show,
            &:hover:not(.no-css-hover) {
                background: rgba(255, 255, 255, 0.75);

                .id,
                .name,
                .label {
                    color: $color-blue-forest;

                    &.name {
                        text-shadow: 0 0 10px rgba(255, 255, 255, 0);
                    }
                }

                &:before {
                    right: calc(100% + 10px);
                    @include opacity(1);
                }

                &:after {
                    left: calc(100% + 10px);
                    @include opacity(1);
                }
            }
        }
    }
}

#module-three-geometry {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    pointer-events: none;
    @include opacity(0);
    @include transition(all, 0.5s, ease-in, 0s);

    canvas {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
    }

    &.show {
        @include opacity(0.2);
    }
}

#module-three-glitch {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    overflow: hidden;
    pointer-events: none;
    @include opacity(0);
    @include transition(opacity, 0.5s, ease-out, 0s);

    canvas {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
    }

    &.show {
        @include transition(opacity, 0.2s, ease-out, 0s);
        @include opacity(0.5);
    }
}

#module-background-font {
    position: absolute;
    top: 50px;
    left: 50px;
    font-size: 300px;
    line-height: 0.9em;
    word-break: break-all;
    mix-blend-mode: overlay;
    background: linear-gradient(to right, #000, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include font-family('Xenox');
    @include opacity(0.2);
}

#module-overlay {

    > div {

        > div {
            position: relative;
            padding: 30px;
            width: 600px;
            background: rgba(0, 0, 0, 0.5);
            overflow: hidden;
            @include backdrop-filter(blur(10px));
            @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

            > .close {
                position: absolute;
                top: 10px;
                right: 10px;
                color: $color-blue-sunny;
                font-size: $font-size-24px;
                line-height: 1em;
                cursor: pointer;
                @include font-family('Inversionz');

                &:after {
                    content: '';
                    position: absolute;
                    top: -10px;
                    bottom: -10px;
                    left: -10px;
                    right: -10px;
                }
            }

            > .content {

                h4 {
                    display: flex;
                    justify-content: space-between;

                    span {
                        color: $color-blue-sunny;
                        font-size: $font-size-24px;
                        line-height: 1em;
                        @include font-family('Inversionz');

                        &.number {
                            position: relative;
                            color: $color-white;

                            span {
                                position: relative;
                                top: 1px;
                                color: $color-blue-sunny;
                                font: inherit;
                                line-height: inherit;
                            }

                            &:before {
                                content: '_';
                                color: $color-blue-sunny;
                                font-size: $font-size-20px;
                                font-weight: 400;
                                letter-spacing: 2px;
                                @include font-family('Roboto');
                            }
                        }
                    }
                }

                form {
                    position: relative;

                    > div {
                        margin: 20px 0;
                        padding: 10px;
                        background: rgba(0, 0, 0, 0.3);
                        @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

                        input {
                            width: 100%;
                            padding: 5px 15px;
                            color: $color-white;
                            border: 1px solid $color-blue-sunny;
                            @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
                            @include placeholder(rgba(255, 255, 255, 0.25));
                        }
                    }

                    > ul {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        height: 300px;
                        margin-right: -20px;
                        padding-right: 10px;
                        overflow-x: hidden;
                        overflow-y: scroll;
                        @include gap(3px 0);

                        > li {
                            display: flex;
                            flex-direction: column;
                            padding: 10px;
                            @include gap(3px 0);

                            h5 {
                                display: flex;
                                justify-content: space-between;
                                margin-bottom: 7px;
                                padding: 5px 10px;
                                line-height: 1em;
                                background: rgba(0, 0, 0, 0.4);
                                @include gap(3px 0);
                                @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

                                span {
                                    line-height: 1em;
                                    font-weight: 500;
                                    color: $color-blue-sunny;

                                    &.number {
                                        position: relative;
                                        top: 1px;
                                        color: $color-white;
                                        font-size: $font-size-24px;
                                        line-height: 14px;
                                        @include font-family('Inversionz');

                                        span {
                                            position: relative;
                                            top: 1px;
                                            color: $color-blue-sunny;
                                            font: inherit;
                                            line-height: inherit;
                                        }

                                        &:before {
                                            content: '_';
                                            position: relative;
                                            top: -2px;
                                            color: $color-blue-sunny;
                                            font-size: $font-size-20px;
                                            font-weight: 400;
                                            letter-spacing: 2px;
                                            @include font-family('Roboto');
                                        }
                                    }
                                }
                            }

                            > ul {
                                display: flex;
                                flex-direction: column;
                                margin-left: 20px;
                                @include gap(3px 0);

                                > li {
                                    width: 100%;

                                    a {
                                        width: 100%;
                                        font-weight: 400;
                                    }
                                }
                            }
                        }
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: -1;
                        height: 300px;
                        background: rgba(0, 0, 0, 0.2);
                        @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
                    }
                }
            }
        }

        &.MuiModal-backdrop {
            background: rgba(33, 206, 245, 0.2) !important;
        }
    }
}

#module-share-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 1;
    @include gap(20px);
    @include opacity(0);
    @include transition(opacity, 0.5s, ease-out, 0s);

    > a,
    > span {
        display: block;
        position: relative;
        font-size: 0;
        cursor: pointer;

        .icon {
            font-size: 16px;
            @include transition(color, 0.2s, ease-out, 0s);
        }

        &:before {
            content: '';
            position: absolute;
            top: -10px;
            bottom: -10px;
            left: -10px;
            right: -10px;
        }

        &:hover:not(.no-css-hover) {

            .icon {
                color: $color-blue-sunny;
            }
        }
    }
}

#module-skill-matrix {

    .table-scroll {
        overflow-x: auto;
        overflow-y: hidden;

        .table-wrapper {
            min-width: 550px;
            overflow: hidden;

            table {
                margin: -3px;
                border-collapse: separate;
                border-spacing: 3px;

                tr {

                    th,
                    td {
                        position: relative;
                        width: 1%;
                        padding: 4px 10px 2px 10px;
                        text-align: left;
                        white-space: nowrap;
                        background: rgba(0, 0, 0, 0.2);
                        @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

                        &:nth-child(1) {
                            width: auto;
                        }
                    }

                    td {

                        &:has(a) {
                            padding: 0;
                        }

                        &:nth-child(1) {
                            font-weight: 400;
                            background: rgba(0, 0, 0, 0.4);

                            > a {
                                display: block;
                                padding: 4px 10px 2px 10px;
                                font-weight: 400;
                                border: 1px solid $transparent;
                                @include transition(border-color, 0.1s, ease-out, 0s);
                                @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

                               &:hover:not(.no-css-hover) {
                                    border-color: $color-white;
                                }
                            }
                        }

                        &:nth-child(3),
                        &:nth-child(4) {
                            text-align: right;
                        }
                    }

                    th {
                        color: $color-blue-sunny;
                        font-weight: 500;
                        background: rgba(0, 0, 0, 0.4);
                    }
                }

                thead {

                    &:after {
                        content: '';
                        display: block;
                        height: 2px;
                    }
                }
            }
        }
    }
}

.mixin-skill-link {
    display: inline-block;
    max-width: 100%;
    padding: 5px 10px;
    line-height: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: rgba(0, 0, 0, 0.6);
    border: 1px solid $transparent !important;
    @include flex(0 0 auto);
    @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
    @include transition(all, 0.1s, ease-out, 0s);

    &:hover:not(.no-css-hover) {
        color: $color-white;
        border-color: $color-white !important;
    }

    &.selected {
        color: $color-blue-forest !important;
        background: $color-white !important;
    }
}

.mixin-skill-search {
    display: inline-block;
}

.mixin-skill-details {
    display: inline-grid;
    width: 100%;

    > h3 {
        display: flex;
        position: relative;
        padding: 3px 10px 1px 10px;
        border: 1px solid $transparent;
        background: rgba(0, 0, 0, 0.3);
        @include gap(0 10px);
        @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
        @include transition(all, 0.1s, ease-out, 0s);

        > a {
            position: relative;
            top: 1px;
            color: $color-blue-sunny;
            font-size: $font-size-24px;
            line-height: 22px;
            @include flex(0 0);
            @include user-select(none);
            @include font-family('Inversionz');
            @include transition(all, 0.1s, ease-out, 0s);

            &:before {
                content: '';
                position: absolute;
                top: -7px;
                bottom: -1px;
                left: -11px;
                right: -8px;
            }
        }

        .text {
            @include flex(1 0);
        }

        &:hover:not(.no-css-hover) {
            border-color: $color-white;
        }
    }

    > div {
        overflow: hidden;
        @include transition(max-height, 0.3s, $ease-in-out-cubic, 0s);

        > ul {
            display: flex;
            flex-direction: column;
            padding: 5px 10px 7px 10px;
            @include gap(3px);

            > li {

                > a {
                    display: flex;
                    padding: 3px 10px 1px 10px;
                    background: rgba(0, 0, 0, 0.3);
                    border: 1px solid $transparent;
                    @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
                    @include transition(all, 0.1s, ease-out, 0s);
                    @include gap(0 10px);

                    > span {

                        &.name {
                            color: $color-blue-sunny;
                            font-weight: 400;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            @include flex(1 1 auto);
                        }

                        &.date {
                            @include flex(0 0);
                        }
                    }

                    &:hover:not(.no-css-hover) {
                        border-color: $color-white;
                    }
                }
            }
        }
    }
}

.mixin-collapse-control {
    top: -1px;
    color: $color-blue-sunny;
    font-size: $font-size-26px;
    line-height: 22px;
    cursor: pointer;
    @include flex(0 0);
    @include user-select(none);
    @include font-family('Inversionz');
    @include transition(all, 0.1s, ease-out, 0s);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 34px;
        right: 0;
    }
}

.mixin-video-player {
    width: 100%;
    margin-bottom: 20px;
    @include opacity(0.3);
    @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
    @include transition(opacity, 0.5s, ease-out, 0s);

    &:hover:not(.no-css-hover),
    &.playing {
        @include opacity(1);
    }
}

.mixin-export-data {
    position: absolute;
    top: 0;
    right: 20px;
}

[data-pathname^='/services'],
[data-pathname^='/insights'] {

    #main {

        .stage-line-horizontal {
            width: 100vw;
        }

        .stage-line-vertical {
            height: calc(100vh - 60px);
        }
    }

    #module-nav-default {

        a {

            span {
                &:nth-child(1) {

                    &:after {
                        @include rotate-z(0, 45deg, 0, 100%);
                    }
                }

                &:nth-child(2) {

                    &:after {
                        width: 0;
                    }
                }

                &:nth-child(3) {

                    &:after {
                        width: 0;
                    }
                }

                &:nth-child(4) {

                    &:after {
                        top: -1px;
                        width: 100%;
                        @include rotate-z(0, -45deg, 0, 0);
                    }
                }
            }
        }
    }

    #module-three-cylinder {
        @include opacity(0);
        @include transition(opacity, 0.5s, ease-out, 0s);
    }
}

[data-pathname^='/services'] {

    #main {

        > section {
            flex-direction: column;

            > .stage {
                display: flex !important;
                align-items: center;
                @include flex(1 0);
            }
        }
    }
}

#page-error {
    background: $color-black;
}

html.isSafari {

    #main {

        > section {
            top: 0 !important;
        }
    }
}

@keyframes cloud-1 {

    from {
        background-position: 50% 0;
    }

    to {
        background-position: -50% 0;
    }
}

@keyframes cloud-2 {

    from {
        background-position: 0 0;
    }

    to {
        background-position: 100% 0;
    }
}

@media screen and (max-width: 1400px) {

    #main {

        > section {

            > .stage {
                display: block;
                max-width: 800px;
                padding-bottom: 0;
                overflow-y: auto;
                scrollbar-color: rgba(144, 187, 198, 0.3) $transparent;
                scrollbar-width: thin;

                > .content {
                    max-height: none;
                    padding-right: 10px;
                    margin-bottom: 20px;
                    overflow-y: hidden;

                    .btn {
                        right: 10px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {

    #main {

        > section {

            > .stage {
                margin-right: 0;
                padding-right: 30px;

                > .content {
                    padding-right: 0;

                    .btn {
                        right: 0;
                    }
                }
            }
        }
    }

    #module-nav-insights {
        padding-left: 30px;
        padding-right: 30px;
    }

    #module-share-links {
        flex-direction: row-reverse;
        bottom: -40px;
        right: -40px;
    }
}

@media screen and (max-width: 600px) {

    #main {

        > section {
            flex-direction: column;
            flex-wrap: nowrap;
            right: 0;
            overflow: hidden;

            > .stage {
                padding-left: 20px;
                padding-right: 20px;

                > .content {

                    > div {

                        > article {

                            > h3,
                            > p {

                                > span.key {
                                    @include flex(0 0 100px);
                                }
                            }
                        }
                    }
                }
            }
        }

        .stage-line-vertical {
            display: none;
        }
    }

    #footer {

        #slideup {

            .links {
                padding: 0 20px;
            }
        }
    }

    #module-nav-default {
        left: 10px;
    }

    #module-nav-services {
        z-index: 3;
    }

    #module-nav-insights {
        flex-wrap: wrap;
        justify-content: right;
        margin-left: 60px;
        padding-right: 0;
        max-width: calc(100% - 85px);
        height: auto;
        @include gap(10px 25px);
    }

    #module-skill-matrix {

        .ctrl {
            display: none !important;
        }
    }

    #module-share-links {
        right: 25px;
    }

    .mixin-export-data {
        display: none !important;
    }
}
