@charset "UTF-8";
/* == CORE / GLOBALS / BASICS ====================================================== */
/* == COMMON ====================================================== */
/* == FLEX ====================================================== */
/* == COLUMN ====================================================== */
/* == GAP ====================================================== */
/* == TEXT & FONTS ====================================================== */
/* == BOX-SHADOW ====================================================== */
/* == GRADIENT ====================================================== */
/* == ROTATE ====================================================== */
/* == SCALE ====================================================== */
/* == CALC ====================================================== */
/* == CLIP-PATH ====================================================== */
/* == TRANSFORM ====================================================== */
/* == TRANSITION ====================================================== */
/* @include transition(opacity, 0.5s, ease-out, 0s); */
/* == ANIMATION ====================================================== */
/* == CURSOR ====================================================== */
/* == IMG ====================================================== */
/* == BG-IMG ====================================================== */
/* == RESET ====================================================== */
* {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  color: #000;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.6em;
  vertical-align: top;
  background: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  height: 100%;
}

body {
  font-family: "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  min-height: 100%;
  position: relative;
}

address,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}

picture {
  display: inline-block;
}
picture img {
  width: 100%;
  height: 100%;
}

img {
  display: inline-block;
  font-size: 0;
  width: auto;
  height: auto;
}

ul {
  font-size: 0;
  list-style-type: none;
}

a {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

a:active,
a:hover,
a:focus,
img:focus {
  border: none;
  outline: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  background: #000;
}

em {
  font: inherit;
  color: inherit;
}

form {
  display: block;
}

input,
select {
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

textarea {
  resize: none;
  overflow: auto;
  vertical-align: top;
  border: 1px solid #000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type=radio] {
  -webkit-appearance: radio;
  -moz-appearance: radio;
  appearance: radio;
}

input[type=checkbox] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  appearance: checkbox;
}

input[type=text],
input[type=number],
input[type=password] {
  border: 1px solid #000;
}

audio,
canvas,
video {
  display: block;
}

video {
  object-fit: cover;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

b,
strong {
  font-weight: bold;
}

svg:not(:root) {
  overflow: hidden;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
}

input[type=search] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[class^=icon-],
[class*=" icon-"] {
  display: inline-block;
  position: relative;
  font-family: "icomoon" !important;
  speak: none !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  text-align: center !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^=icon-] span,
[class*=" icon-"] span {
  font-family: "icomoon" !important;
  speak: inherit !important;
  font-style: inherit !important;
  font-weight: inherit !important;
  font-variant: inherit !important;
  text-transform: inherit !important;
  line-height: inherit;
}
[class^=icon-] .visually-hidden,
[class*=" icon-"] .visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  border: none;
  margin: -1px;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}
[class^=icon-]:before,
[class*=" icon-"]:before {
  position: relative;
  z-index: 1;
}

/* == NATIVE - COMMON ====================================================== */
.clear-both {
  clear: both;
}

.clear-both-i {
  clear: both !important;
}

.cursor-pointer {
  cursor: pointer;
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-space-nowrap-i {
  white-space: nowrap !important;
}

.white-space-normal {
  white-space: normal;
}

.white-space-normal-i {
  white-space: normal !important;
}

.visibility-hidden {
  visibility: hidden;
}

.word-break-break-all {
  word-break: break-all;
}

.list-style-position-inside {
  list-style-position: inside;
}

.list-style-type-decimal-leading-zero {
  list-style-type: decimal-leading-zero;
}

.gap-0-10px {
  -webkit-gap: 0 10px;
  -moz-gap: 0 10px;
  -ms-gap: 0 10px;
  -o-gap: 0 10px;
  gap: 0 10px;
}

.column-count-2 {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  -ms-column-count: 2;
  -o-column-count: 2;
  column-count: 2;
}

/* == NATIVE - DISPLAY ====================================================== */
.display-none {
  display: none;
}

.display-none-i {
  display: none !important;
}

.display-block {
  display: block;
}

.display-block-i {
  display: block !important;
}

.display-inline {
  display: inline;
}

.display-inline-i {
  display: inline !important;
}

.display-inline-block {
  display: inline-block;
}

.display-inline-block-i {
  display: inline-block !important;
}

/* == NATIVE - FLEX ====================================================== */
.flex-0-0-auto {
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
}

.flex-0-0-auto-i {
  -webkit-flex: 0 0 auto !important;
  -moz-flex: 0 0 auto !important;
  -ms-flex: 0 0 auto !important;
  -o-flex: 0 0 auto !important;
  flex: 0 0 auto !important;
}

.flex-1-0-auto {
  -webkit-flex: 1 0 auto;
  -moz-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  -o-flex: 1 0 auto;
  flex: 1 0 auto;
}

.flex-1-0-auto-i {
  -webkit-flex: 1 0 auto !important;
  -moz-flex: 1 0 auto !important;
  -ms-flex: 1 0 auto !important;
  -o-flex: 1 0 auto !important;
  flex: 1 0 auto !important;
}

.flex-0-1-auto {
  -webkit-flex: 0 1 auto;
  -moz-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  -o-flex: 0 1 auto;
  flex: 0 1 auto;
}

.flex-0-1-auto-i {
  -webkit-flex: 0 1 auto !important;
  -moz-flex: 0 1 auto !important;
  -ms-flex: 0 1 auto !important;
  -o-flex: 0 1 auto !important;
  flex: 0 1 auto !important;
}

.flex-1-1-auto {
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}

.flex-1-1-auto-i {
  -webkit-flex: 1 1 auto !important;
  -moz-flex: 1 1 auto !important;
  -ms-flex: 1 1 auto !important;
  -o-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-0-0-100p {
  -webkit-flex: 0 0 100%;
  -moz-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  -o-flex: 0 0 100%;
  flex: 0 0 100%;
}

.flex-0-0-100p-i {
  -webkit-flex: 0 0 100% !important;
  -moz-flex: 0 0 100% !important;
  -ms-flex: 0 0 100% !important;
  -o-flex: 0 0 100% !important;
  flex: 0 0 100% !important;
}

.display-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-right {
  justify-content: right;
}

.justify-content-right-i {
  justify-content: right !important;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-center-i {
  justify-content: center !important;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-between-i {
  justify-content: space-between !important;
}

/* == NATIVE - POSITION ====================================================== */
.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

/* == NATIVE - Z-INDEX ====================================================== */
.z-index--1 {
  z-index: -1;
}

.z-index--1-i {
  z-index: -1 !important;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-2-i {
  z-index: 2 !important;
}

.z-index-3 {
  z-index: 3;
}

.z-index-3-i {
  z-index: 3 !important;
}

.z-index-10 {
  z-index: 10;
}

.z-index-10-i {
  z-index: 10 !important;
}

/* == NATIVE - FLOAT ====================================================== */
.float-left {
  float: left;
}

.float-left-i {
  float: left !important;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

.float-none-i {
  float: none !important;
}

/* == NATIVE - TEXT ====================================================== */
.text-align-left {
  text-align: left;
}

.text-align-left-i {
  text-align: left !important;
}

.text-align-right {
  text-align: right;
}

.text-align-right-i {
  text-align: right !important;
}

.text-align-center {
  text-align: center;
}

.text-align-center-i {
  text-align: center !important;
}

.text-transform-lowercase {
  text-transform: lowercase;
}

.text-transform-lowercase-i {
  text-transform: lowercase !important;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.text-transform-uppercase-i {
  text-transform: uppercase !important;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.text-decoration-none-i {
  text-decoration: none !important;
}

/* == NATIVE - VERTICAL-ALIGN ====================================================== */
.vertical-align-top {
  vertical-align: top;
}

.vertical-align-top-i {
  vertical-align: top !important;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.vertical-align-baseline {
  vertical-align: baseline;
}

.vertical-align-inherit {
  vertical-align: inherit;
}

/* == NATIVE - OVERFLOW ====================================================== */
.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

/* == NATIVE - BORDER ====================================================== */
.border-top-none {
  border-top: none;
}

.border-bottom-none {
  border-bottom: none;
}

.border-bottom-none-i {
  border-bottom: none !important;
}

.border-radius-3px {
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}

.border-radius-5px {
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -ms-border-radius: 5px 5px 5px 5px;
  -o-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

.border-radius-10px {
  -webkit-border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -ms-border-radius: 10px 10px 10px 10px;
  -o-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
}

.border-radius-20px {
  -webkit-border-radius: 20px 20px 20px 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  -ms-border-radius: 20px 20px 20px 20px;
  -o-border-radius: 20px 20px 20px 20px;
  border-radius: 20px 20px 20px 20px;
}

.border-radius-30px {
  -webkit-border-radius: 30px 30px 30px 30px;
  -moz-border-radius: 30px 30px 30px 30px;
  -ms-border-radius: 30px 30px 30px 30px;
  -o-border-radius: 30px 30px 30px 30px;
  border-radius: 30px 30px 30px 30px;
}

/* == NATIVE VALUES - TOP ====================================================== */
.top-0 {
  top: 0;
}

.top-2px {
  top: 2px;
}

.top--2px {
  top: -2px;
}

.top--2px-i {
  top: -2px !important;
}

.top-3px {
  top: 3px;
}

.top-3px-i {
  top: 3px !important;
}

.top-4px {
  top: 4px;
}

.top-5px {
  top: 5px;
}

.top-8px {
  top: 8px;
}

.top-100 {
  top: 100%;
}

/* == NATIVE VALUES - BOTTOM ====================================================== */
.bottom-0 {
  bottom: 0;
}

.bottom-10px {
  bottom: 10px;
}

/* == NATIVE VALUES - RIGHT ====================================================== */
.left-auto-i {
  left: auto !important;
}

.left-0 {
  left: 0;
}

.left-10 {
  left: 10px;
}

/* == NATIVE VALUES - RIGHT ====================================================== */
.right-0 {
  right: 0;
}

.right-10px {
  right: 10px;
}

/* == NATIVE VALUES - WIDTH ====================================================== */
.width-20px {
  width: 20px;
}

.width-20px-i {
  width: 20px !important;
}

.width-33px {
  width: 33px;
}

.width-34px {
  width: 34px;
}

.width-50px {
  width: 50px;
}

.width-80px {
  width: 80px;
}

.width-100px {
  width: 100px;
}

.width-110px {
  width: 110px;
}

.width-120px {
  width: 120px;
}

.width-120px-i {
  width: 120px !important;
}

.width-140px {
  width: 140px;
}

.width-150px {
  width: 150px;
}

.width-160px {
  width: 160px;
}

.width-170px {
  width: 170px;
}

.width-200px {
  width: 200px;
}

.width-250px {
  width: 250px;
}

.width-280px {
  width: 280px;
}

.width-300px {
  width: 300px;
}

.width-330px {
  width: 330px;
}

.width-350px {
  width: 350px;
}

.width-400px {
  width: 400px;
}

.width-500px {
  width: 500px;
}

.width-600px {
  width: 600px;
}

.width-100p {
  width: 100%;
}

/* == NATIVE VALUES - MIN WIDTH ====================================================== */
.min-width-300px {
  min-width: 300px;
}

.min-width-400px {
  min-width: 400px;
}

.min-width-640px {
  min-width: 640px;
}

/* == NATIVE VALUES - MAX-WIDTH ====================================================== */
.max-width-100px {
  max-width: 100px;
}

.max-width-110px {
  max-width: 110px;
}

.max-width-130px {
  max-width: 130px;
}

.max-width-170px {
  max-width: 170px;
}

.max-width-200px {
  max-width: 200px;
}

.max-width-280px-i {
  max-width: 280px !important;
}

.max-width-600px {
  max-width: 600px;
}

/* == NATIVE VALUES - HEIGHT ====================================================== */
.height-auto-i {
  height: auto !important;
}

.height-1px {
  height: 1px;
}

.height-20px {
  height: 20px;
}

.height-26px {
  height: 26px;
}

.height-34px {
  height: 34px;
}

.height-100px {
  height: 100px;
}

.height-100px-i {
  height: 100px !important;
}

.height-195px {
  height: 195px;
}

.height-350px {
  height: 350px;
}

.height-050p {
  height: 50%;
}

.height-050p-i {
  height: 50% !important;
}

.height-100p {
  height: 100%;
}

.height-100p-i {
  height: 100% !important;
}

/* == NATIVE VALUES - MIN HEIGHT ====================================================== */
.min-height-40px {
  min-height: 40px;
}

.min-height-40px-i {
  min-height: 40px !important;
}

/* == NATIVE VALUES - MAX HEIGHT ====================================================== */
.max-height-100px {
  max-height: 100px;
}

/* == NATIVE VALUES - MARGIN ====================================================== */
.margin-3px {
  margin: 3px;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-0-i {
  margin-top: 0 !important;
}

.margin-top-2px {
  margin-top: 2px;
}

.margin-top-4px {
  margin-top: 4px;
}

.margin-top-5px {
  margin-top: 5px;
}

.margin-top-10px {
  margin-top: 10px;
}

.margin-top-10px-i {
  margin-top: 10px !important;
}

.margin-top-20px {
  margin-top: 20px;
}

.margin-top-20px-i {
  margin-top: 20px !important;
}

.margin-top-25px {
  margin-top: 25px;
}

.margin-top-25px-i {
  margin-top: 25px !important;
}

.margin-top-30px {
  margin-top: 30px;
}

.margin-top-30px-i {
  margin-top: 30px !important;
}

.margin-top-40px {
  margin-top: 40px;
}

.margin-top-40px-i {
  margin-top: 40px !important;
}

.margin-top-50px {
  margin-top: 50px;
}

.margin-top-50px-i {
  margin-top: 50px !important;
}

.margin-top-60px {
  margin-top: 60px;
}

.margin-top-80px {
  margin-top: 80px;
}

.margin-top--1px {
  margin-top: -1px;
}

.margin-top--20px {
  margin-top: -20px;
}

.margin-top--34px {
  margin-top: -34px;
}

.margin-top--060p {
  margin-top: -60%;
}

.margin-top--100p {
  margin-top: -100%;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-left-5px {
  margin-left: 5px;
}

.margin-left-10px {
  margin-left: 10px;
}

.margin-left-20px {
  margin-left: 20px;
}

.margin-left-25px {
  margin-left: 25px;
}

.margin-left-30px {
  margin-left: 30px;
}

.margin-left-310px {
  margin-left: 310px;
}

.margin-right-10px {
  margin-right: 10px;
}

.margin-right-20px {
  margin-right: 20px;
}

.margin-right-30px {
  margin-right: 30px;
}

.margin-right-40px {
  margin-right: 40px;
}

.margin-right-48px {
  margin-right: 48px;
}

.margin-right-60px {
  margin-right: 60px;
}

.margin-right--10px {
  margin-right: -10px;
}

.margin-right--20px {
  margin-right: -20px;
}

.margin-right--40px {
  margin-right: -40px;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-bottom-0-i {
  margin-bottom: 0 !important;
}

.margin-bottom-10px {
  margin-bottom: 10px;
}

.margin-bottom-10px-i {
  margin-bottom: 10px !important;
}

.margin-bottom-20px {
  margin-bottom: 20px;
}

.margin-bottom-40px {
  margin-bottom: 40px;
}

.margin-bottom-40px-i {
  margin-bottom: 40px !important;
}

.margin-bottom-60px {
  margin-bottom: 60px;
}

.margin-bottom-60px-i {
  margin-bottom: 60px !important;
}

.margin-bottom-80px {
  margin-bottom: 80px;
}

.margin-bottom--10px {
  margin-bottom: -10px;
}

.margin-bottom--10px-i {
  margin-bottom: -10px !important;
}

.margin-bottom--50px {
  margin-bottom: -50px;
}

.margin-bottom--240px {
  margin-bottom: -240px;
}

.margin-vertical--20px {
  margin-left: -20px;
  margin-right: -20px;
}

/* == NATIVE VALUES - PADDING ====================================================== */
.adding-0 {
  padding: 0;
}

.padding-0-i {
  padding: 0 !important;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-0-i {
  padding-top: 0 !important;
}

.padding-top-10px {
  padding-top: 10px;
}

.padding-top-20px {
  padding-top: 20px;
}

.padding-top-20px-i {
  padding-top: 20px !important;
}

.padding-top-25px {
  padding-top: 25px;
}

.padding-top-30px {
  padding-top: 30px;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-0-i {
  padding-bottom: 0 !important;
}

.padding-bottom-5px {
  padding-bottom: 5px;
}

.padding-bottom-10px {
  padding-bottom: 10px;
}

.padding-bottom-20px {
  padding-bottom: 20px;
}

.padding-bottom-20px-i {
  padding-bottom: 20px !important;
}

.padding-bottom-25px {
  padding-bottom: 25px;
}

.padding-bottom-30px {
  padding-bottom: 30px;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-0-i {
  padding-left: 0 !important;
}

.padding-left-3px-i {
  padding-left: 3px !important;
}

.padding-left-5px {
  padding-left: 5px;
}

.padding-left-10px {
  padding-left: 10px;
}

.padding-left-20px {
  padding-left: 20px;
}

.padding-left-25px-i {
  padding-left: 25px !important;
}

.padding-left-150px {
  padding-left: 150px;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-0-i {
  padding-right: 0 !important;
}

.padding-right-3px {
  padding-right: 3px;
}

.padding-right-3px-i {
  padding-right: 3px !important;
}

.padding-right-5px {
  padding-right: 5px;
}

.padding-right-10px {
  padding-right: 10px;
}

.padding-right-20px {
  padding-right: 20px;
}

.padding-right-43px-i {
  padding-right: 43px !important;
}

.padding-horizontal-3px {
  padding-left: 3px;
  padding-right: 3px;
}

.padding-horizontal-4px {
  padding-left: 4px;
  padding-right: 4px;
}

.padding-horizontal-5px {
  padding-left: 5px;
  padding-right: 5px;
}

.padding-horizontal-20px {
  padding-left: 20px;
  padding-right: 20px;
}

.padding-vertical-3px {
  padding-top: 3px;
  padding-bottom: 3px;
}

.padding-vertical-5px {
  padding-top: 5px;
  padding-bottom: 5px;
}

.padding-vertical-20px {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-3px {
  padding: 3px;
}

.padding-10px {
  padding: 10px;
}

/* == NATIVE VALUES - FONT SIZE ====================================================== */
.font-size-0 {
  font-size: 0;
}

.font-size-1em {
  font-size: 1em;
}

.font-size-1c6em {
  font-size: 1.6em;
}

.font-size-12px {
  font-size: 12px;
}

.font-size-14px {
  font-size: 14px;
}

.font-size-18px {
  font-size: 18px;
}

.font-size-20px {
  font-size: 20px;
}

.font-size-22px {
  font-size: 22px;
}

.font-size-24px {
  font-size: 24px;
}

.font-size-26px {
  font-size: 26px;
}

.font-size-28px {
  font-size: 28px;
}

.font-size-30px {
  font-size: 30px;
}

.font-size-32px {
  font-size: 32px;
}

.font-size-34px {
  font-size: 34px;
}

.font-size-38px {
  font-size: 38px;
}

.font-size-40px {
  font-size: 40px;
}

.font-size-80px {
  font-size: 80px;
}

/* == NATIVE VALUES - FONT WEIGHT ====================================================== */
.font-weight-200 {
  font-weight: 200;
}

.font-weight-200-i {
  font-weight: 200 !important;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-300-i {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-400-i {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-500-i {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-600-i {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-700-i {
  font-weight: 700 !important;
}

/* == NATIVE VALUES - LINE HEIGHT ====================================================== */
.line-height-1em {
  line-height: 1em;
}

.line-height-26px {
  line-height: 26px;
}

.line-height-34px {
  line-height: 34px;
}

.line-height-37px {
  line-height: 37px;
}

/* == OPACITY ====================================================== */
.opacity-0 {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}

.opacity-0-i {
  opacity: 0 !important;
  filter: alpha(opacity=0) !important;
  -ms-filter: alpha(opacity=0) !important;
}

.opacity-1-i {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
  -ms-filter: alpha(opacity=100) !important;
}

/* == HOVER ====================================================== */
.hover-text-decoration-underline:hover:hover:not(.no-css-hover) {
  text-decoration: underline;
}

/* == TAGS ====================================================== */
body {
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
  background: #fff;
  -webkit-transition: opacity 0.5s ease-out 0s;
  -moz-transition: opacity 0.5s ease-out 0s;
  -ms-transition: opacity 0.5s ease-out 0s;
  -o-transition: opacity 0.5s ease-out 0s;
  transition: opacity 0.5s ease-out 0s;
}
body.scrolling iframe {
  pointer-events: none;
}
body.resizing * {
  -webkit-transition: all 0s linear 0s !important;
  -moz-transition: all 0s linear 0s !important;
  -ms-transition: all 0s linear 0s !important;
  -o-transition: all 0s linear 0s !important;
  transition: all 0s linear 0s !important;
}

img,
[data-lazy] {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 1s ease-out 0s;
  -moz-transition: opacity 1s ease-out 0s;
  -ms-transition: opacity 1s ease-out 0s;
  -o-transition: opacity 1s ease-out 0s;
  transition: opacity 1s ease-out 0s;
}
img.img-loaded,
[data-lazy].img-loaded {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
img.img-lazy-waiting,
[data-lazy].img-lazy-waiting {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}
img.img-lazy-loaded,
[data-lazy].img-lazy-loaded {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

/* == COMMON ====================================================== */
.box-square {
  position: relative;
  overflow: hidden;
}
.box-square:before {
  content: "";
  display: block;
  aspect-ratio: 1;
  height: 100%;
}
.box-square .box-square-ctn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.box-shadow {
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.box-shadow.box-shadow-huge {
  -webkit-box-shadow: 0 0 10px 0 rgb(0, 0, 0);
  -moz-box-shadow: 0 0 10px 0 rgb(0, 0, 0);
  -ms-box-shadow: 0 0 10px 0 rgb(0, 0, 0);
  -o-box-shadow: 0 0 10px 0 rgb(0, 0, 0);
  box-shadow: 0 0 10px 0 rgb(0, 0, 0);
}
.box-shadow.box-shadow-small {
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.css-table {
  display: table;
  width: 100%;
  height: 100%;
}
.css-table .css-cell {
  display: table-cell;
  vertical-align: middle;
}

.text-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
}

.line-break:after {
  content: "\a";
  white-space: pre;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  border: none;
  margin: -1px;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.crop-at-show-more,
.crop-at-show-less {
  display: inline-block;
  padding-left: 5px;
  cursor: pointer;
}

.crop-text {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ctn-capitalize {
  position: relative;
}
.ctn-capitalize .ctn-cap {
  position: relative;
  color: inherit;
  font: inherit;
}

.clm-wrapper {
  display: flex;
  font-size: 0;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}
.clm-wrapper > .clm {
  display: inline-block;
}
.clm-wrapper > .clm.clm-1 {
  width: 100%;
}
.clm-wrapper > .clm.clm-2 {
  width: 50%;
}
.clm-wrapper > .clm.clm-3 {
  width: 33.333333%;
}
.clm-wrapper > .clm.clm-4 {
  width: 25%;
}
.clm-wrapper > .clm.clm-5 {
  width: 20%;
}
.clm-wrapper > .clm.clm-6 {
  width: 16.666666%;
}
.clm-wrapper > .clm.clm-7 {
  width: 14.285%;
}
.clm-wrapper > .clm.clm-7x3 {
  width: 42.855%;
}
.clm-wrapper > .clm.clm-7x4 {
  width: 57.14%;
}
.clm-wrapper > .clm.clm-8 {
  width: 12.5%;
}
.clm-wrapper > .clm.clm-9 {
  width: 11.111111%;
}
.clm-wrapper > .clm.clm-20p {
  width: 20%;
}
.clm-wrapper > .clm.clm-25p {
  width: 25%;
}
.clm-wrapper > .clm.clm-30p {
  width: 30%;
}
.clm-wrapper > .clm.clm-33p {
  width: 33.333333%;
}
.clm-wrapper > .clm.clm-35p {
  width: 35%;
}
.clm-wrapper > .clm.clm-40p {
  width: 40%;
}
.clm-wrapper > .clm.clm-45p {
  width: 45%;
}
.clm-wrapper > .clm.clm-55p {
  width: 55%;
}
.clm-wrapper > .clm.clm-50p {
  width: 50%;
}
.clm-wrapper > .clm.clm-60p {
  width: 60%;
}
.clm-wrapper > .clm.clm-65p {
  width: 65%;
}
.clm-wrapper > .clm.clm-66p {
  width: 66.666666%;
}
.clm-wrapper > .clm.clm-70p {
  width: 70%;
}
.clm-wrapper > .clm.clm-75p {
  width: 75%;
}
.clm-wrapper > .clm.clm-80p {
  width: 80%;
}
.clm-wrapper > .clm.clm-83p {
  width: 83.334%;
}
.clm-wrapper.nowrap {
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -o-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.uniform {
  display: inline-block;
  position: relative;
  margin-right: 5px;
  width: 25px;
  height: 25px;
}
.uniform input {
  position: absolute;
  z-index: 1;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
}
.uniform.uniform-radio:before {
  bottom: 3px;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
.uniform.uniform-radio:after {
  bottom: 8px;
  width: 8px;
  height: 8px;
  background: #181818;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}
.uniform.uniform-checkbox:before {
  bottom: 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
.uniform.uniform-checkbox:after {
  content: "✓";
  height: 1em;
  bottom: 8px;
  opacity: 0.8;
  filter: alpha(opacity=80);
  -ms-filter: alpha(opacity=80);
}
.uniform.uniform-checked:after {
  display: block;
}
.uniform:before, .uniform:after {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.uniform:before {
  width: 16px;
  height: 16px;
  border: 1px solid #181818;
  opacity: 0.3;
  filter: alpha(opacity=30);
  -ms-filter: alpha(opacity=30);
}
.uniform:after {
  display: none;
}

html.isFirefox .uniform.uniform-checkbox:before {
  bottom: 3px;
}

[data-set-parent-height] {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
[data-set-parent-height][style] {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

[data-trigger-href] {
  cursor: pointer;
}

[data-clone] {
  cursor: pointer;
}

/* == OLD BROWSER ====================================================== */
#old-browser {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #181818;
}
#old-browser h1 {
  padding: 0 20px;
  color: #fff;
  font-size: 30px;
  line-height: 1.3em;
  background: none;
}
#old-browser p {
  margin-top: 20px;
  padding: 0 20px;
  color: #fff;
  font-size: 20px;
  font-weight: 100;
  line-height: 1.6em;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
}
#old-browser p span,
#old-browser p a {
  margin: 0;
  padding: 0;
  color: inherit;
  font: inherit;
}
#old-browser p a {
  text-decoration: underline;
  cursor: pointer;
}

/* == CORE / GLOBALS / PLUGINS ====================================================== */
.grecaptcha-badge {
  display: none !important;
}

/* == CORE / GLOBALS / MODULES ====================================================== */
#main {
  position: relative;
}

/* == CORE / APP / BASICS ====================================================== */
/* == CORE / APP / HELPERS ====================================================== */
[data-vue] {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0.2s;
  -moz-transition: opacity 0.3s ease-out 0.2s;
  -ms-transition: opacity 0.3s ease-out 0.2s;
  -o-transition: opacity 0.3s ease-out 0.2s;
  transition: opacity 0.3s ease-out 0.2s;
}
[data-vue][data-v-app] {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

/* == CORE / APP / PAGES ====================================================== */
#page-error {
  margin: 0;
  padding: 0;
  height: 100%;
  text-align: center;
  background-image: none;
  overflow-y: auto;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#page-error h1 {
  padding: 0 20px;
  font-size: 70px;
  line-height: 1em;
}
#page-error p {
  margin-top: 20px;
  padding: 0 20px;
  font-size: 30px;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
}
#page-error a {
  margin-top: 20px;
  text-decoration: underline;
}
#page-error .icon {
  margin-bottom: 30px;
  color: #333;
  font-size: 100px;
}

@media screen and (max-width: 767px) {
  #page-error .icon {
    font-size: 60px;
  }
  #page-error h1 {
    font-size: 40px;
  }
  #page-error p {
    font-size: 20px;
  }
}
#page-maintenance {
  margin: 0;
  padding: 0;
  height: 100%;
  text-align: center;
  background-image: none;
  overflow-y: auto;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#page-maintenance h1 {
  font-size: 30px;
  line-height: 1.3em;
}
#page-maintenance form {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 400px;
  text-align: left;
}
#page-maintenance form label.mandatory:after {
  content: "*";
  margin-left: 5px;
}
#page-maintenance form input {
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#page-maintenance form input[type=submit] {
  padding: 4px 5px;
  color: #fff;
  background: #333;
  text-transform: uppercase;
}
#page-maintenance .icon-excavator {
  margin-bottom: 30px;
  color: #333;
  font-size: 100px;
}
#page-maintenance .subline {
  margin-top: 10px;
  font-size: 20px;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
}

/* == PROJECT / APP / BASICS ====================================================== */
/* == Xenox ====================================================== */
@font-face {
  font-family: "Xenox";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../../fonts/Xenox/Xenox.woff2?1743160544778") format("woff2");
}
/* == Inversionz ====================================================== */
@font-face {
  font-family: "Inversionz";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../../fonts/Inversionz/Inversionz Unboxed.woff2?1743160544778") format("woff2");
}
/* == Google Fonts - Roboto - Thin ====================================================== */
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"), url(https://fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1MmgVxGIzIXKMnyrYk.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"), url(https://fonts.gstatic.com/s/roboto/v18/KFOkCnqEu92Fr1MmgVxIIzIXKMny.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* == Google Fonts - Roboto - Light ====================================================== */
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fChc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* == Google Fonts - Roboto - Regular ====================================================== */
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* == Google Fonts - Roboto - Medium ====================================================== */
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fChc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* == Google Fonts - Roboto - Bold ====================================================== */
/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfChc4AMP6lbBP.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "icomoon";
  src: url("../icons/app/icomoon.eot?1743160544778");
  src: url("../icons/app/icomoon.eot?1743160544778#iefix") format("embedded-opentype"), url("../icons/app/icomoon.ttf?1743160544778") format("truetype"), url("../icons/app/icomoon.woff?1743160544778") format("woff"), url("../icons/app/icomoon.svg?1743160544778#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.icon-logo-icon-x:before {
  content: "\e901";
}

.icon-logo-icon-linkedin:before {
  content: "\e902";
}

.icon-logo-icon-xing:before {
  content: "\e903";
}

.icon-share:before {
  content: "\e900";
}

* {
  color: #fff;
  font-size: 14px;
  scrollbar-color: rgba(144, 187, 198, 0.3) rgba(0, 0, 0, 0);
  scrollbar-width: thin;
  font-family: "Roboto", sans-serif;
}
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(144, 187, 198, 0.3);
  border-radius: 3px;
}
*::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
body.footer-is-visible {
  overflow-y: auto;
}

#main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#main > section {
  display: flex;
  position: absolute;
  top: 100vh;
  bottom: 61px;
  left: 0;
  right: 71px;
  z-index: 3;
  overflow: hidden;
  background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(17, 44, 51, 0.75));
  -webkit-backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  -ms-backdrop-filter: blur(10px);
  -o-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-gap: 0 40px;
  -moz-gap: 0 40px;
  -ms-gap: 0 40px;
  -o-gap: 0 40px;
  gap: 0 40px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#main > section > .stage {
  display: flex;
  position: relative;
  z-index: 1;
  width: 100%;
  margin-right: 70px;
  padding: 0 5px 0 30px;
  max-height: calc(100% - 72px);
  -webkit-gap: 60px;
  -moz-gap: 60px;
  -ms-gap: 60px;
  -o-gap: 60px;
  gap: 60px;
}
#main > section > .stage h2 {
  margin-bottom: 20px;
  color: #21cef5;
  font-size: 30px;
  line-height: 1em;
  font-family: "Inversionz", sans-serif;
}
#main > section > .stage > .content {
  position: relative;
  max-width: 800px;
  max-height: 100%;
  padding-right: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-flex: 1 1 calc(50% - 20px);
  -moz-flex: 1 1 calc(50% - 20px);
  -ms-flex: 1 1 calc(50% - 20px);
  -o-flex: 1 1 calc(50% - 20px);
  flex: 1 1 calc(50% - 20px);
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
#main > section > .stage > .content > p {
  margin-bottom: 10px;
  hyphens: auto;
}
#main > section > .stage > .content > div {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  -webkit-gap: 20px;
  -moz-gap: 20px;
  -ms-gap: 20px;
  -o-gap: 20px;
  gap: 20px;
}
#main > section > .stage > .content > div > article {
  display: flex;
  flex-direction: column;
  padding: 8px 10px;
  background: rgba(0, 0, 0, 0.3);
  -webkit-gap: 3px 0;
  -moz-gap: 3px 0;
  -ms-gap: 3px 0;
  -o-gap: 3px 0;
  gap: 3px 0;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#main > section > .stage > .content > div > article > h3,
#main > section > .stage > .content > div > article > p {
  display: flex;
  -webkit-gap: 0 3px;
  -moz-gap: 0 3px;
  -ms-gap: 0 3px;
  -o-gap: 0 3px;
  gap: 0 3px;
}
#main > section > .stage > .content > div > article > h3 > span,
#main > section > .stage > .content > div > article > p > span {
  padding: 4px 10px 2px 10px;
  background: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#main > section > .stage > .content > div > article > h3 > span.key,
#main > section > .stage > .content > div > article > p > span.key {
  font-weight: 400;
  background: rgba(0, 0, 0, 0.4);
  -webkit-flex: 0 0 120px;
  -moz-flex: 0 0 120px;
  -ms-flex: 0 0 120px;
  -o-flex: 0 0 120px;
  flex: 0 0 120px;
}
#main > section > .stage > .content > div > article > h3 > span.value,
#main > section > .stage > .content > div > article > p > span.value {
  display: flex;
  hyphens: auto;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex: 1 0;
  -moz-flex: 1 0;
  -ms-flex: 1 0;
  -o-flex: 1 0;
  flex: 1 0;
  -webkit-gap: 3px;
  -moz-gap: 3px;
  -ms-gap: 3px;
  -o-gap: 3px;
  gap: 3px;
}
#main > section > .stage > .content > div > article > h3 > span.value.value:has(.mixin-skill-link),
#main > section > .stage > .content > div > article > p > span.value.value:has(.mixin-skill-link) {
  max-width: 100%;
  padding: 5px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#main > section > .stage > .content > div > article > h3 > span.value > a,
#main > section > .stage > .content > div > article > p > span.value > a {
  font-weight: 400;
}
#main > section > .stage > .content > div > article > h3 > span.value > a:not(.mixin-skill-link),
#main > section > .stage > .content > div > article > p > span.value > a:not(.mixin-skill-link) {
  color: #21cef5;
  text-decoration: underline;
}
#main > section > .stage > .content > div > article > h3 > span {
  background: rgba(0, 0, 0, 0.4);
}
#main > section > .stage > .content > div > article > h3 > span.value {
  font-weight: 400;
  color: #21cef5;
}
#main > section > .stage > .content > div > article > h3 > span.value.color-white {
  color: #fff;
}
#main > section > .stage > .content > div > article > ul {
  display: flex;
  flex-direction: row;
  -webkit-gap: 3px;
  -moz-gap: 3px;
  -ms-gap: 3px;
  -o-gap: 3px;
  gap: 3px;
}
#main > section > .stage > .content > div > article > ul > li {
  -webkit-flex: 0 0;
  -moz-flex: 0 0;
  -ms-flex: 0 0;
  -o-flex: 0 0;
  flex: 0 0;
}
#main > section > .stage > .content > div > article > .number {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 1em;
  text-align: right;
  font-family: "Inversionz", sans-serif;
}
#main > section > .stage > .content > div > article > .number:before {
  content: "_";
  color: #21cef5;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 2px;
  font-family: "Roboto", sans-serif;
}
#main > section > .stage > .content > div > article > .number:after {
  content: "---";
  position: absolute;
  top: -2px;
  left: 5px;
  color: #21cef5;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 2px;
  font-family: "Roboto", sans-serif;
}
#main > section > .stage > .content .btn {
  display: flex;
  height: 26px;
  padding: 1px 10px 0 10px;
  background: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0);
  -webkit-gap: 0 5px;
  -moz-gap: 0 5px;
  -ms-gap: 0 5px;
  -o-gap: 0 5px;
  gap: 0 5px;
  -webkit-transition: border-color 0.1s ease-out 0s;
  -moz-transition: border-color 0.1s ease-out 0s;
  -ms-transition: border-color 0.1s ease-out 0s;
  -o-transition: border-color 0.1s ease-out 0s;
  transition: border-color 0.1s ease-out 0s;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#main > section > .stage > .content .btn .text {
  color: #fff;
  font-weight: 400;
  white-space: nowrap;
}
#main > section > .stage > .content .btn .font-icon {
  color: #21cef5;
  font-size: 24px;
  line-height: 1em;
  font-family: "Inversionz", sans-serif;
}
#main > section > .stage > .content .btn:hover:not(.no-css-hover) {
  border-color: #fff;
}
#main > section > .stage > .matrix {
  -webkit-flex: 1 1 calc(50% - 20px);
  -moz-flex: 1 1 calc(50% - 20px);
  -ms-flex: 1 1 calc(50% - 20px);
  -o-flex: 1 1 calc(50% - 20px);
  flex: 1 1 calc(50% - 20px);
}
#main > section.show-section {
  top: 0;
  overflow: visible;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#main > section.show-section.show-content > .stage > .content {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#main > section.show-section #module-share-links {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#main .stage-line-horizontal {
  position: absolute;
  bottom: 60px;
  right: 0;
  z-index: 10;
  width: 0;
  height: 1px;
  background: #fff;
  pointer-events: none;
  -webkit-transition: width 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: width 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: width 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: width 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: width 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#main .stage-line-vertical {
  position: absolute;
  top: 0;
  right: 70px;
  z-index: 10;
  width: 1px;
  height: 0;
  background: linear-gradient(to top, #fff 0%, #69cfd8 25%, #46899a 50%);
  pointer-events: none;
  -webkit-transition: height 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: height 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: height 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: height 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: height 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#main:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 600vw;
  height: 100%;
  pointer-events: none;
  opacity: 0.2;
  filter: alpha(opacity=20);
  -ms-filter: alpha(opacity=20);
  background-image: url("../images/assets/app/layout/cloud-01.png?1743160544778");
  background-position: bottom center;
  background-repeat: repeat-x;
  background-size: 300vw 100vh;
  -webkit-animation: cloud-1 180s linear infinite 0s forwards;
  -moz-animation: cloud-1 180s linear infinite 0s forwards;
  -ms-animation: cloud-1 180s linear infinite 0s forwards;
  -o-animation: cloud-1 180s linear infinite 0s forwards;
  animation: cloud-1 180s linear infinite 0s forwards;
}
#main:after {
  content: "";
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 1;
  width: 600vw;
  height: 50%;
  pointer-events: none;
  opacity: 0.3;
  filter: alpha(opacity=30);
  -ms-filter: alpha(opacity=30);
  background-image: url("../images/assets/app/layout/cloud-02.png?1743160544778");
  background-position: bottom center;
  background-repeat: repeat-x;
  background-size: 300vw 50vh;
  -webkit-animation: cloud-2 240s linear infinite 0s forwards;
  -moz-animation: cloud-2 240s linear infinite 0s forwards;
  -ms-animation: cloud-2 240s linear infinite 0s forwards;
  -o-animation: cloud-2 240s linear infinite 0s forwards;
  animation: cloud-2 240s linear infinite 0s forwards;
}

#header {
  position: relative;
  width: 100%;
  height: 100%;
}
#header h1 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../images/assets/app/layout/background.jpg?1743160544778");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#header h1 span {
  display: none;
}
#header:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: linear-gradient(rgba(0, 68, 84, 0.05) 1px, transparent 1px), linear-gradient(90deg, rgba(0, 68, 84, 0.05) 1px, transparent 1px);
  background-size: 3px 3px;
}
#header:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 60%;
  pointer-events: none;
  background: -webkit-linear-gradient(top, rgba(0, 68, 84, 0.5) 0, rgba(0, 0, 0, 0) 100%);
  background: -moz-linear-gradient(top, rgba(0, 68, 84, 0.5) 0, rgba(0, 0, 0, 0) 100%);
  background: -ms-linear-gradient(top, rgba(0, 68, 84, 0.5) 0, rgba(0, 0, 0, 0) 100%);
  background: -o-linear-gradient(top, rgba(0, 68, 84, 0.5) 0, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(top, rgba(0, 68, 84, 0.5) 0, rgba(0, 0, 0, 0) 100%);
}

#footer {
  position: relative;
  z-index: 2;
}
#footer #slideup {
  background: -webkit-linear-gradient(top, #fff 0, #004454 1px);
  background: -moz-linear-gradient(top, #fff 0, #004454 1px);
  background: -ms-linear-gradient(top, #fff 0, #004454 1px);
  background: -o-linear-gradient(top, #fff 0, #004454 1px);
  background: linear-gradient(top, #fff 0, #004454 1px);
}
#footer #slideup .links {
  display: flex;
  gap: 0 20px;
  position: absolute;
  top: -60px;
  padding: 0 30px;
}
#footer #slideup .links .label {
  margin: 0;
  font-size: 16px;
  line-height: 60px;
  text-decoration: underline;
  cursor: pointer;
}
#footer #slideup .close {
  top: 20px;
  right: 30px;
  color: #fff;
  font-size: 16px;
  text-decoration: underline;
}
#footer #slideup .content {
  padding: 60px 30px 20px 30px;
  border-top: 1px solid #fff;
}
#footer #slideup .content h4 {
  margin-left: -10px;
  padding-bottom: 20px;
  font-size: 40px;
  line-height: 1em;
  font-family: "Inversionz", sans-serif;
}
#footer #slideup .content h5 {
  padding: 20px 0 10px 0;
  font-weight: 400;
  text-transform: uppercase;
}
#footer #slideup .content p {
  padding-bottoM: 10px;
  color: #fff;
}
#footer #slideup .content a {
  text-decoration: underline;
}
#footer #slideup .content ul {
  padding-top: 20px;
}
#footer #slideup .content ul li {
  position: relative;
  padding-left: 20px;
  color: #fff;
}
#footer #slideup .content ul li:before {
  content: "";
  position: absolute;
  top: 9px;
  left: 0;
  width: 6px;
  height: 6px;
  background: #fff;
  -webkit-border-radius: 50% 50% 50% 50%;
  -moz-border-radius: 50% 50% 50% 50%;
  -ms-border-radius: 50% 50% 50% 50%;
  -o-border-radius: 50% 50% 50% 50%;
  border-radius: 50% 50% 50% 50%;
}

#module-nav-default {
  position: absolute;
  top: 18px;
  left: 20px;
  z-index: 10;
}
#module-nav-default a {
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
#module-nav-default a span {
  display: block;
  position: relative;
  margin-bottom: 3px;
  width: 100%;
  height: 2px;
}
#module-nav-default a span:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#module-nav-default a span:last-child {
  margin-bottom: 0;
}
#module-nav-default a span:nth-child(2):after {
  width: 10px;
}
#module-nav-default a span:nth-child(3):after {
  width: 15px;
}
#module-nav-default a span:nth-child(4):after {
  width: 5px;
}
#module-nav-default a:hover:not(.no-css-hover) span:after {
  width: 100%;
}

#module-nav-insights {
  display: flex;
  gap: 0 25px;
  justify-content: right;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 70px;
  padding: 25px 40px;
}
#module-nav-insights a {
  font-weight: 400;
}
#module-nav-insights a:before, #module-nav-insights a:after {
  position: relative;
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -ms-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
#module-nav-insights a:before {
  content: "[";
  left: -15px;
}
#module-nav-insights a:after {
  content: "]";
  right: -15px;
}
#module-nav-insights a.active:before, #module-nav-insights a.active:after, #module-nav-insights a:hover:not(.no-css-hover):before, #module-nav-insights a:hover:not(.no-css-hover):after {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#module-nav-insights a.active:before, #module-nav-insights a:hover:not(.no-css-hover):before {
  content: "[";
  left: -3px;
}
#module-nav-insights a.active:after, #module-nav-insights a:hover:not(.no-css-hover):after {
  content: "]";
  right: -3px;
}

#module-nav-services {
  display: flex;
  gap: 0 15px;
  justify-content: right;
  position: absolute;
  top: 30px;
  right: 20px;
  z-index: 4;
  writing-mode: vertical-rl;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: perspective(0) rotateZ(180deg);
  -moz-transform: rotateZ(180deg);
  -ms-transform: perspective(0) rotateZ(180deg);
  -o-transform: perspective(0) rotateZ(180deg);
  transform: perspective(0) rotateZ(180deg);
}
#module-nav-services a {
  position: relative;
  display: inline-block;
  color: #fff;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
#module-nav-services a:before, #module-nav-services a:after {
  position: relative;
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -ms-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}
#module-nav-services a:before {
  content: "[";
  bottom: 15px;
}
#module-nav-services a:after {
  content: "]";
  top: 15px;
}
#module-nav-services a.active:before, #module-nav-services a.active:after, #module-nav-services a:hover:not(.no-css-hover):before, #module-nav-services a:hover:not(.no-css-hover):after {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#module-nav-services a.active:before, #module-nav-services a:hover:not(.no-css-hover):before {
  content: "[";
  bottom: 3px;
}
#module-nav-services a.active:after, #module-nav-services a:hover:not(.no-css-hover):after {
  content: "]";
  top: 3px;
}

#module-three-cylinder {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  overflow: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: all 6s ease-in 0s;
  -moz-transition: all 6s ease-in 0s;
  -ms-transition: all 6s ease-in 0s;
  -o-transition: all 6s ease-in 0s;
  transition: all 6s ease-in 0s;
}
#module-three-cylinder canvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
#module-three-cylinder .skill {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
  padding: 10px;
  cursor: pointer;
  pointer-events: none !important;
  -webkit-transition: background 0.3s ease-out 0s;
  -moz-transition: background 0.3s ease-out 0s;
  -ms-transition: background 0.3s ease-out 0s;
  -o-transition: background 0.3s ease-out 0s;
  transition: background 0.3s ease-out 0s;
}
#module-three-cylinder .skill .id {
  color: rgba(127, 255, 255, 0.75);
  font-size: 18px;
  font-weight: 400;
  line-height: 1em;
  text-align: right;
  -webkit-flex: 1 0;
  -moz-flex: 1 0;
  -ms-flex: 1 0;
  -o-flex: 1 0;
  flex: 1 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
#module-three-cylinder .skill .name {
  max-height: 60px;
  color: rgba(255, 255, 255, 0.75);
  font-size: 40px;
  font-weight: 600;
  line-height: 0.7em;
  text-align: center;
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.95);
  word-break: break-word;
  letter-spacing: -5px;
  overflow: hidden;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
  font-family: "Inversionz", sans-serif;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
#module-three-cylinder .skill .name span {
  display: inline-block;
  margin: 0 -5px;
  color: inherit;
  font: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}
#module-three-cylinder .skill .label {
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-top: 5px;
  color: rgba(127, 255, 255, 0.75);
  font-size: 18px;
  font-weight: 400;
  line-height: 1em;
  text-align: center;
  text-transform: uppercase;
  -webkit-flex: 1 0;
  -moz-flex: 1 0;
  -ms-flex: 1 0;
  -o-flex: 1 0;
  flex: 1 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
#module-three-cylinder .skill:before {
  content: "[";
  position: absolute;
  top: 50px;
  right: calc(100% + 30px);
  transform: scale(3, 11);
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
#module-three-cylinder .skill:after {
  content: "]";
  position: absolute;
  top: 50px;
  left: calc(100% + 30px);
  transform: scale(3, 11);
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
#module-three-cylinder.show {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#module-three-cylinder.loaded {
  -webkit-transition: all 0.5s ease-in 0s;
  -moz-transition: all 0.5s ease-in 0s;
  -ms-transition: all 0.5s ease-in 0s;
  -o-transition: all 0.5s ease-in 0s;
  transition: all 0.5s ease-in 0s;
}
#module-three-cylinder.loaded .skill {
  pointer-events: auto !important;
}
#module-three-cylinder.loaded .skill:hover:not(.no-css-hover) {
  background: rgba(255, 255, 255, 0.75);
}
#module-three-cylinder.loaded .skill:hover:not(.no-css-hover) .id,
#module-three-cylinder.loaded .skill:hover:not(.no-css-hover) .name,
#module-three-cylinder.loaded .skill:hover:not(.no-css-hover) .label {
  color: #112c33;
}
#module-three-cylinder.loaded .skill:hover:not(.no-css-hover) .id.name,
#module-three-cylinder.loaded .skill:hover:not(.no-css-hover) .name.name,
#module-three-cylinder.loaded .skill:hover:not(.no-css-hover) .label.name {
  text-shadow: 0 0 10px rgba(255, 255, 255, 0);
}
#module-three-cylinder.loaded .skill:hover:not(.no-css-hover):before {
  right: calc(100% + 10px);
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}
#module-three-cylinder.loaded .skill:hover:not(.no-css-hover):after {
  left: calc(100% + 10px);
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

#module-three-geometry {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: all 0.5s ease-in 0s;
  -moz-transition: all 0.5s ease-in 0s;
  -ms-transition: all 0.5s ease-in 0s;
  -o-transition: all 0.5s ease-in 0s;
  transition: all 0.5s ease-in 0s;
}
#module-three-geometry canvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
#module-three-geometry.show {
  opacity: 0.2;
  filter: alpha(opacity=20);
  -ms-filter: alpha(opacity=20);
}

#module-three-glitch {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.5s ease-out 0s;
  -moz-transition: opacity 0.5s ease-out 0s;
  -ms-transition: opacity 0.5s ease-out 0s;
  -o-transition: opacity 0.5s ease-out 0s;
  transition: opacity 0.5s ease-out 0s;
}
#module-three-glitch canvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
#module-three-glitch.show {
  -webkit-transition: opacity 0.2s ease-out 0s;
  -moz-transition: opacity 0.2s ease-out 0s;
  -ms-transition: opacity 0.2s ease-out 0s;
  -o-transition: opacity 0.2s ease-out 0s;
  transition: opacity 0.2s ease-out 0s;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: alpha(opacity=50);
}

#module-background-font {
  position: absolute;
  top: 50px;
  left: 50px;
  font-size: 300px;
  line-height: 0.9em;
  word-break: break-all;
  mix-blend-mode: overlay;
  background: linear-gradient(to right, #000, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Xenox", sans-serif;
  opacity: 0.2;
  filter: alpha(opacity=20);
  -ms-filter: alpha(opacity=20);
}

#module-overlay > div > div {
  position: relative;
  padding: 30px;
  width: 600px;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  -webkit-backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  -ms-backdrop-filter: blur(10px);
  -o-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#module-overlay > div > div > .close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #21cef5;
  font-size: 24px;
  line-height: 1em;
  cursor: pointer;
  font-family: "Inversionz", sans-serif;
}
#module-overlay > div > div > .close:after {
  content: "";
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
}
#module-overlay > div > div > .content h4 {
  display: flex;
  justify-content: space-between;
}
#module-overlay > div > div > .content h4 span {
  color: #21cef5;
  font-size: 24px;
  line-height: 1em;
  font-family: "Inversionz", sans-serif;
}
#module-overlay > div > div > .content h4 span.number {
  position: relative;
  color: #fff;
}
#module-overlay > div > div > .content h4 span.number span {
  position: relative;
  top: 1px;
  color: #21cef5;
  font: inherit;
  line-height: inherit;
}
#module-overlay > div > div > .content h4 span.number:before {
  content: "_";
  color: #21cef5;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 2px;
  font-family: "Roboto", sans-serif;
}
#module-overlay > div > div > .content form {
  position: relative;
}
#module-overlay > div > div > .content form > div {
  margin: 20px 0;
  padding: 10px;
  background: rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#module-overlay > div > div > .content form > div input {
  width: 100%;
  padding: 5px 15px;
  color: #fff;
  border: 1px solid #21cef5;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#module-overlay > div > div > .content form > div input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.25);
}
#module-overlay > div > div > .content form > div input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.25);
}
#module-overlay > div > div > .content form > div input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.25);
}
#module-overlay > div > div > .content form > div input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.25);
}
#module-overlay > div > div > .content form > ul {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 300px;
  margin-right: -20px;
  padding-right: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-gap: 3px 0;
  -moz-gap: 3px 0;
  -ms-gap: 3px 0;
  -o-gap: 3px 0;
  gap: 3px 0;
}
#module-overlay > div > div > .content form > ul > li {
  display: flex;
  flex-direction: column;
  padding: 10px;
  -webkit-gap: 3px 0;
  -moz-gap: 3px 0;
  -ms-gap: 3px 0;
  -o-gap: 3px 0;
  gap: 3px 0;
}
#module-overlay > div > div > .content form > ul > li h5 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
  padding: 5px 10px;
  line-height: 1em;
  background: rgba(0, 0, 0, 0.4);
  -webkit-gap: 3px 0;
  -moz-gap: 3px 0;
  -ms-gap: 3px 0;
  -o-gap: 3px 0;
  gap: 3px 0;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#module-overlay > div > div > .content form > ul > li h5 span {
  line-height: 1em;
  font-weight: 500;
  color: #21cef5;
}
#module-overlay > div > div > .content form > ul > li h5 span.number {
  position: relative;
  top: 1px;
  color: #fff;
  font-size: 24px;
  line-height: 14px;
  font-family: "Inversionz", sans-serif;
}
#module-overlay > div > div > .content form > ul > li h5 span.number span {
  position: relative;
  top: 1px;
  color: #21cef5;
  font: inherit;
  line-height: inherit;
}
#module-overlay > div > div > .content form > ul > li h5 span.number:before {
  content: "_";
  position: relative;
  top: -2px;
  color: #21cef5;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 2px;
  font-family: "Roboto", sans-serif;
}
#module-overlay > div > div > .content form > ul > li > ul {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  -webkit-gap: 3px 0;
  -moz-gap: 3px 0;
  -ms-gap: 3px 0;
  -o-gap: 3px 0;
  gap: 3px 0;
}
#module-overlay > div > div > .content form > ul > li > ul > li {
  width: 100%;
}
#module-overlay > div > div > .content form > ul > li > ul > li a {
  width: 100%;
  font-weight: 400;
}
#module-overlay > div > div > .content form:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 300px;
  background: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#module-overlay > div.MuiModal-backdrop {
  background: rgba(33, 206, 245, 0.2) !important;
}

#module-share-links {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 1;
  -webkit-gap: 20px;
  -moz-gap: 20px;
  -ms-gap: 20px;
  -o-gap: 20px;
  gap: 20px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.5s ease-out 0s;
  -moz-transition: opacity 0.5s ease-out 0s;
  -ms-transition: opacity 0.5s ease-out 0s;
  -o-transition: opacity 0.5s ease-out 0s;
  transition: opacity 0.5s ease-out 0s;
}
#module-share-links > a,
#module-share-links > span {
  display: block;
  position: relative;
  font-size: 0;
  cursor: pointer;
}
#module-share-links > a .icon,
#module-share-links > span .icon {
  font-size: 16px;
  -webkit-transition: color 0.2s ease-out 0s;
  -moz-transition: color 0.2s ease-out 0s;
  -ms-transition: color 0.2s ease-out 0s;
  -o-transition: color 0.2s ease-out 0s;
  transition: color 0.2s ease-out 0s;
}
#module-share-links > a:before,
#module-share-links > span:before {
  content: "";
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
}
#module-share-links > a:hover:not(.no-css-hover) .icon,
#module-share-links > span:hover:not(.no-css-hover) .icon {
  color: #21cef5;
}

#module-skill-matrix .table-scroll {
  overflow-x: auto;
  overflow-y: hidden;
}
#module-skill-matrix .table-scroll .table-wrapper {
  min-width: 550px;
  overflow: hidden;
}
#module-skill-matrix .table-scroll .table-wrapper table {
  margin: -3px;
  border-collapse: separate;
  border-spacing: 3px;
}
#module-skill-matrix .table-scroll .table-wrapper table tr th,
#module-skill-matrix .table-scroll .table-wrapper table tr td {
  position: relative;
  width: 1%;
  padding: 4px 10px 2px 10px;
  text-align: left;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#module-skill-matrix .table-scroll .table-wrapper table tr th:nth-child(1),
#module-skill-matrix .table-scroll .table-wrapper table tr td:nth-child(1) {
  width: auto;
}
#module-skill-matrix .table-scroll .table-wrapper table tr td:has(a) {
  padding: 0;
}
#module-skill-matrix .table-scroll .table-wrapper table tr td:nth-child(1) {
  font-weight: 400;
  background: rgba(0, 0, 0, 0.4);
}
#module-skill-matrix .table-scroll .table-wrapper table tr td:nth-child(1) > a {
  display: block;
  padding: 4px 10px 2px 10px;
  font-weight: 400;
  border: 1px solid rgba(0, 0, 0, 0);
  -webkit-transition: border-color 0.1s ease-out 0s;
  -moz-transition: border-color 0.1s ease-out 0s;
  -ms-transition: border-color 0.1s ease-out 0s;
  -o-transition: border-color 0.1s ease-out 0s;
  transition: border-color 0.1s ease-out 0s;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
}
#module-skill-matrix .table-scroll .table-wrapper table tr td:nth-child(1) > a:hover:not(.no-css-hover) {
  border-color: #fff;
}
#module-skill-matrix .table-scroll .table-wrapper table tr td:nth-child(3), #module-skill-matrix .table-scroll .table-wrapper table tr td:nth-child(4) {
  text-align: right;
}
#module-skill-matrix .table-scroll .table-wrapper table tr th {
  color: #21cef5;
  font-weight: 500;
  background: rgba(0, 0, 0, 0.4);
}
#module-skill-matrix .table-scroll .table-wrapper table thead:after {
  content: "";
  display: block;
  height: 2px;
}

.mixin-skill-link {
  display: inline-block;
  max-width: 100%;
  padding: 5px 10px;
  line-height: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(0, 0, 0, 0) !important;
  -webkit-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  -webkit-transition: all 0.1s ease-out 0s;
  -moz-transition: all 0.1s ease-out 0s;
  -ms-transition: all 0.1s ease-out 0s;
  -o-transition: all 0.1s ease-out 0s;
  transition: all 0.1s ease-out 0s;
}
.mixin-skill-link:hover:not(.no-css-hover) {
  color: #fff;
  border-color: #fff !important;
}
.mixin-skill-link.selected {
  color: #112c33 !important;
  background: #fff !important;
}

.mixin-skill-search {
  display: inline-block;
}

.mixin-skill-details {
  display: inline-grid;
  width: 100%;
}
.mixin-skill-details > h3 {
  display: flex;
  position: relative;
  padding: 3px 10px 1px 10px;
  border: 1px solid rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0.3);
  -webkit-gap: 0 10px;
  -moz-gap: 0 10px;
  -ms-gap: 0 10px;
  -o-gap: 0 10px;
  gap: 0 10px;
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  -webkit-transition: all 0.1s ease-out 0s;
  -moz-transition: all 0.1s ease-out 0s;
  -ms-transition: all 0.1s ease-out 0s;
  -o-transition: all 0.1s ease-out 0s;
  transition: all 0.1s ease-out 0s;
}
.mixin-skill-details > h3 > a {
  position: relative;
  top: 1px;
  color: #21cef5;
  font-size: 24px;
  line-height: 22px;
  -webkit-flex: 0 0;
  -moz-flex: 0 0;
  -ms-flex: 0 0;
  -o-flex: 0 0;
  flex: 0 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  font-family: "Inversionz", sans-serif;
  -webkit-transition: all 0.1s ease-out 0s;
  -moz-transition: all 0.1s ease-out 0s;
  -ms-transition: all 0.1s ease-out 0s;
  -o-transition: all 0.1s ease-out 0s;
  transition: all 0.1s ease-out 0s;
}
.mixin-skill-details > h3 > a:before {
  content: "";
  position: absolute;
  top: -7px;
  bottom: -1px;
  left: -11px;
  right: -8px;
}
.mixin-skill-details > h3 .text {
  -webkit-flex: 1 0;
  -moz-flex: 1 0;
  -ms-flex: 1 0;
  -o-flex: 1 0;
  flex: 1 0;
}
.mixin-skill-details > h3:hover:not(.no-css-hover) {
  border-color: #fff;
}
.mixin-skill-details > div {
  overflow: hidden;
  -webkit-transition: max-height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: max-height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: max-height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: max-height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: max-height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
.mixin-skill-details > div > ul {
  display: flex;
  flex-direction: column;
  padding: 5px 10px 7px 10px;
  -webkit-gap: 3px;
  -moz-gap: 3px;
  -ms-gap: 3px;
  -o-gap: 3px;
  gap: 3px;
}
.mixin-skill-details > div > ul > li > a {
  display: flex;
  padding: 3px 10px 1px 10px;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0);
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  -webkit-transition: all 0.1s ease-out 0s;
  -moz-transition: all 0.1s ease-out 0s;
  -ms-transition: all 0.1s ease-out 0s;
  -o-transition: all 0.1s ease-out 0s;
  transition: all 0.1s ease-out 0s;
  -webkit-gap: 0 10px;
  -moz-gap: 0 10px;
  -ms-gap: 0 10px;
  -o-gap: 0 10px;
  gap: 0 10px;
}
.mixin-skill-details > div > ul > li > a > span.name {
  color: #21cef5;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-flex: 1 1 auto;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  -o-flex: 1 1 auto;
  flex: 1 1 auto;
}
.mixin-skill-details > div > ul > li > a > span.date {
  -webkit-flex: 0 0;
  -moz-flex: 0 0;
  -ms-flex: 0 0;
  -o-flex: 0 0;
  flex: 0 0;
}
.mixin-skill-details > div > ul > li > a:hover:not(.no-css-hover) {
  border-color: #fff;
}

.mixin-collapse-control {
  top: -1px;
  color: #21cef5;
  font-size: 26px;
  line-height: 22px;
  cursor: pointer;
  -webkit-flex: 0 0;
  -moz-flex: 0 0;
  -ms-flex: 0 0;
  -o-flex: 0 0;
  flex: 0 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  font-family: "Inversionz", sans-serif;
  -webkit-transition: all 0.1s ease-out 0s;
  -moz-transition: all 0.1s ease-out 0s;
  -ms-transition: all 0.1s ease-out 0s;
  -o-transition: all 0.1s ease-out 0s;
  transition: all 0.1s ease-out 0s;
}
.mixin-collapse-control:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 34px;
  right: 0;
}

.mixin-video-player {
  width: 100%;
  margin-bottom: 20px;
  opacity: 0.3;
  filter: alpha(opacity=30);
  -ms-filter: alpha(opacity=30);
  -webkit-border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -ms-border-radius: 3px 3px 3px 3px;
  -o-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  -webkit-transition: opacity 0.5s ease-out 0s;
  -moz-transition: opacity 0.5s ease-out 0s;
  -ms-transition: opacity 0.5s ease-out 0s;
  -o-transition: opacity 0.5s ease-out 0s;
  transition: opacity 0.5s ease-out 0s;
}
.mixin-video-player:hover:not(.no-css-hover), .mixin-video-player.playing {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
}

.mixin-export-data {
  position: absolute;
  top: 0;
  right: 20px;
}

[data-pathname^="/services"] #main .stage-line-horizontal,
[data-pathname^="/insights"] #main .stage-line-horizontal {
  width: 100vw;
}
[data-pathname^="/services"] #main .stage-line-vertical,
[data-pathname^="/insights"] #main .stage-line-vertical {
  height: calc(100vh - 60px);
}
[data-pathname^="/services"] #module-nav-default a span:nth-child(1):after,
[data-pathname^="/insights"] #module-nav-default a span:nth-child(1):after {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: perspective(0) rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: perspective(0) rotateZ(45deg);
  -o-transform: perspective(0) rotateZ(45deg);
  transform: perspective(0) rotateZ(45deg);
}
[data-pathname^="/services"] #module-nav-default a span:nth-child(2):after,
[data-pathname^="/insights"] #module-nav-default a span:nth-child(2):after {
  width: 0;
}
[data-pathname^="/services"] #module-nav-default a span:nth-child(3):after,
[data-pathname^="/insights"] #module-nav-default a span:nth-child(3):after {
  width: 0;
}
[data-pathname^="/services"] #module-nav-default a span:nth-child(4):after,
[data-pathname^="/insights"] #module-nav-default a span:nth-child(4):after {
  top: -1px;
  width: 100%;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: perspective(0) rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: perspective(0) rotateZ(-45deg);
  -o-transform: perspective(0) rotateZ(-45deg);
  transform: perspective(0) rotateZ(-45deg);
}
[data-pathname^="/services"] #module-three-cylinder,
[data-pathname^="/insights"] #module-three-cylinder {
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.5s ease-out 0s;
  -moz-transition: opacity 0.5s ease-out 0s;
  -ms-transition: opacity 0.5s ease-out 0s;
  -o-transition: opacity 0.5s ease-out 0s;
  transition: opacity 0.5s ease-out 0s;
}

[data-pathname^="/services"] #main > section {
  flex-direction: column;
}
[data-pathname^="/services"] #main > section > .stage {
  display: flex !important;
  align-items: center;
  -webkit-flex: 1 0;
  -moz-flex: 1 0;
  -ms-flex: 1 0;
  -o-flex: 1 0;
  flex: 1 0;
}

#page-error {
  background: #000;
}

html.isSafari #main > section {
  top: 0 !important;
}

@keyframes cloud-1 {
  from {
    background-position: 50% 0;
  }
  to {
    background-position: -50% 0;
  }
}
@keyframes cloud-2 {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
@media screen and (max-width: 1400px) {
  #main > section > .stage {
    display: block;
    max-width: 800px;
    padding-bottom: 0;
    overflow-y: auto;
    scrollbar-color: rgba(144, 187, 198, 0.3) rgba(0, 0, 0, 0);
    scrollbar-width: thin;
  }
  #main > section > .stage > .content {
    max-height: none;
    padding-right: 10px;
    margin-bottom: 20px;
    overflow-y: hidden;
  }
  #main > section > .stage > .content .btn {
    right: 10px;
  }
  #main > section > .stage > .content:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 800px) {
  #main > section > .stage {
    margin-right: 0;
    padding-right: 30px;
  }
  #main > section > .stage > .content {
    padding-right: 0;
  }
  #main > section > .stage > .content .btn {
    right: 0;
  }
  #module-nav-insights {
    padding-left: 30px;
    padding-right: 30px;
  }
  #module-share-links {
    flex-direction: row-reverse;
    bottom: -40px;
    right: -40px;
  }
}
@media screen and (max-width: 600px) {
  #main > section {
    flex-direction: column;
    flex-wrap: nowrap;
    right: 0;
    overflow: hidden;
  }
  #main > section > .stage {
    padding-left: 20px;
    padding-right: 20px;
  }
  #main > section > .stage > .content > div > article > h3 > span.key,
  #main > section > .stage > .content > div > article > p > span.key {
    -webkit-flex: 0 0 100px;
    -moz-flex: 0 0 100px;
    -ms-flex: 0 0 100px;
    -o-flex: 0 0 100px;
    flex: 0 0 100px;
  }
  #main .stage-line-vertical {
    display: none;
  }
  #footer #slideup .links {
    padding: 0 20px;
  }
  #module-nav-default {
    left: 10px;
  }
  #module-nav-services {
    z-index: 3;
  }
  #module-nav-insights {
    flex-wrap: wrap;
    justify-content: right;
    margin-left: 60px;
    padding-right: 0;
    max-width: calc(100% - 85px);
    height: auto;
    -webkit-gap: 10px 25px;
    -moz-gap: 10px 25px;
    -ms-gap: 10px 25px;
    -o-gap: 10px 25px;
    gap: 10px 25px;
  }
  #module-skill-matrix .ctrl {
    display: none !important;
  }
  #module-share-links {
    right: 25px;
  }
  .mixin-export-data {
    display: none !important;
  }
}
/* == CORE / GLOBALS / PLUGINS ====================================================== */
/* == CORE / GLOBALS / MIXINS ====================================================== */
/* == CORE / GLOBALS / MODULES ====================================================== */
/* == CORE / APP / PLUGINS ====================================================== */
/* == CORE / APP / MODULES ====================================================== */
#slideup .links {
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-gap: 0 20px;
  -moz-gap: 0 20px;
  -ms-gap: 0 20px;
  -o-gap: 0 20px;
  gap: 0 20px;
}
#slideup .links .label {
  cursor: pointer;
}
#slideup .contents {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -moz-transition: height 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -ms-transition: height 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  -o-transition: height 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  transition: height 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}
#slideup .contents .close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
#slideup .contents .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: alpha(opacity=0);
  -webkit-transition: opacity 0.3s ease-out 0s;
  -moz-transition: opacity 0.3s ease-out 0s;
  -ms-transition: opacity 0.3s ease-out 0s;
  -o-transition: opacity 0.3s ease-out 0s;
  transition: opacity 0.3s ease-out 0s;
}
#slideup .contents .content.show {
  pointer-events: initial;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
  -webkit-transition: opacity 0.3s ease-out 0.3s;
  -moz-transition: opacity 0.3s ease-out 0.3s;
  -ms-transition: opacity 0.3s ease-out 0.3s;
  -o-transition: opacity 0.3s ease-out 0.3s;
  transition: opacity 0.3s ease-out 0.3s;
}
#slideup .contents.show .close {
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: alpha(opacity=100);
  -webkit-transition: opacity 0.3s ease-out 1s;
  -moz-transition: opacity 0.3s ease-out 1s;
  -ms-transition: opacity 0.3s ease-out 1s;
  -o-transition: opacity 0.3s ease-out 1s;
  transition: opacity 0.3s ease-out 1s;
}

/* == CORE / APP / PAGES ====================================================== */
/* == PROJECT / APP / PLUGINS ====================================================== */
/* == PROJECT / APP / MIXINS ====================================================== */
/* == PROJECT / APP / MODULES ====================================================== */
/* == PROJECT / APP / PAGES ====================================================== */